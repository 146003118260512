import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Image from './components/image/image.component';
import IdvSdkComponent from './components/sdk/idv-sdk.component';
import Text from './components/text/text.component';
import { useErrorNavigation } from './hooks/error-navigation';
import { useFlowContext } from './hooks/verification-flow';
import RootRoutes from './routes/main.routes';
import { pathPrefix } from './utils/base-url-path';
import { captureException } from './utils/monitoring/sentry';
import { getParamFromUrl } from './utils/url-format/get-param-from-url';

import './app.scss';

const App: React.FC = function () {
  const { t } = useTranslation();
  const { state } = useFlowContext();
  const [startToken, setStartToken] = React.useState<string>();
  const { handleError } = useErrorNavigation();

  useEffect(() => {
    const uiConfig = window.uiConfig;
    const token = getParamFromUrl(pathPrefix + ':startToken');
    setStartToken(token);
    if (!uiConfig) {
      const additionalInfo = { startToken: startToken };
      captureException(new Error('UI Config not found'), additionalInfo);
      handleError(new Error('UI Config not found'), 'onLoad');
    }
  }, []);

  return (
    <>
      <RootRoutes />
      {(state.sdkSettings?.sdk_enabled && startToken && <IdvSdkComponent startToken={startToken} />) || <></>}
      <div className={'landscape-overlay'}>
        <Image src={'/verify/app/assets/rotate-device.svg'} /> <br />
        <Text testId="rotate_your_screen">{t('rotate_your_screen')}</Text> <br />
        <Text strong testId="view_in_portrait">
          {t('view_in_portrait')}
        </Text>
      </div>
    </>
  );
};

export default App;
